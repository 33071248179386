@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: $ui-transparent;
  overflow-y: auto;
}

.modal.show {
  display: flex;
}

.modal.hide {
  display: none;
}

.modal.scroll {
  align-items: flex-start;
}

.modal-inner {
  width: 100%;
  position: relative;
}

/* === LOADER START === */
.loader {
  height: 100%;
}

.loader-overlay {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.loader-gif {
  width: 50px;
  height: 50px;
}
/* === LOADER END === */

.inter-alert-overlay {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .inter-alert-body {
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 20px;
    border-radius: 8px;
    background-color: $ui-bg-white;
    box-shadow: 0 0 4px rgba($violet, 0.45);

    h3 {
      margin-bottom: 8px;
      text-align: center;
    }
    
    p {
      margin-bottom: 20px;
      text-align: center;
    }

    .inter-alert-body-buttons {
      justify-content: space-between;
      width: 100%;

      button {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

/* Modals Close Button */
.modal-close-button {
  position: absolute;
  right: -16px;
  top: -16px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border: 1px solid $ui-icon-gray;
  border-radius: 50%;
  background-color: $ui-bg-white;
}

.modal-close-button-icon {
  width: 16px;
  height: 16px;
  fill: $ui-icon-gray;
}

/* Modals Body */
.modal-body {
  flex-direction: column;
  width: 100%;
  padding: 40px;
  border-radius: 2px;
  background-color: $ui-bg-white;
}

@media all and (max-width: $ipad) {
  .modal {
    padding: 16px;
  }

  .modal-close-button {
    right: 12px;
    top: 8px;
    z-index: 9;
    background-color: $ui-bg-white;
  }

  .modal-body {
    width: 100%;
  }
}

@media all and (max-width: $tablet) {
  .modal {
    align-items: flex-start;
  }

  .modal-inner {
    width: 100%;
  }

  .modal-body {
    flex-direction: column;
  }
}
