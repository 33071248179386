@import "../../styles/colors.scss";

.paginator {
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 8px 0;

  .paginator-inner {
    max-width: 640px;
  }

  .paginator-button-wrap {
    flex-grow: 0;
    flex-shrink: 0;

    .paginator-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      padding: 0 12px;
      border: 1px solid $ui-line-gray;
      border-radius: 8px;
      background-color: $ui-btn-white;
      font-size: 13px;
      font-weight: 500;

      &.disabled {
        background-color: $ui-btn-gray;
        opacity: 0.35;
      }
    }
  }

  .paginator-num-buttons {
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 40px;

    .paginator-num {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 34px;
      margin: 0 2px;
      border: 1px solid $ui-line-gray;
      border-radius: 8px;
      background-color: $ui-btn-white;
      font-size: 13px;
      font-weight: 500;

      &.active {
        background-color: $ui-btn-disabled;
        color: $ui-text-white;
      }
    }

    .paginator-spots {
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 34px;
      margin: 0 2px;
      padding-top: 6px;
    }
  }
}


@media (max-width:960px) { 
  .prev {
    display: none;
  }
  .next {
    display: none;
  }
 } 