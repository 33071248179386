@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.modal-catalog {
  width: 680px;

  .modal-catalog-form {
    flex-direction: column;
    width: 100%;
    margin-top: 32px;

    .modal-catalog-form-images {
      margin-bottom: 16px;
    }
  }
}