@import "./colors.scss";
@import "./fonts.scss";
@import "./media.scss";

* {
  box-sizing: border-box;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: $ui-bg-white;
  color: $ui-text-black;
  font: {
    family: "Montserrat", sans-serif;
    weight: 400;
  }
  @include font-size($ui-font-13);
  letter-spacing: 0;
}

div,
footer,
header,
nav,
section {
  display: flex;
}

/* UI BASE ELEMENTS */

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font: {
    family: "Montserrat", sans-serif;
    weight: 600;
  }
}

p {
  margin: 0;
  color: $ui-text-black;
  font: {
    family: "Montserrat", sans-serif;
    weight: 400;
  }
  @include font-size($ui-font-13);
  letter-spacing: 0;
}

a {
  cursor: pointer;
  color: $ui-text-black;
  text-decoration: none;
  transition: color 0.15s ease-out 0s;
}

button {
  cursor: pointer;
  padding: 0;
  border-width: 0;
  background-color: transparent;
  color: $ui-text-black;
  font: {
    family: "Montserrat", sans-serif;
    weight: 600;
  }
  @include font-size($ui-font-15);
  transition: all 0.3s ease-out 0s;

  &:focus {
    outline: none;
  }
  &:hover {
    transition: all 0.3s ease-out 0s;
  }
}

.button {
  height: 52px;
  padding: 0 16px;
  border: {
    radius: 8px;
    style: solid;
    width: 1px;
  }
}

.button span {
  white-space: nowrap;
}

input {
  padding: 10px 0;
  border: {
    color: transparent;
    width: 0;
  }
  background-color: transparent;
  font: {
    family: "Montserrat", sans-serif;
    weight: 400;
  }
  @include font-size($ui-font-13);

  &:focus {
    outline: none;
  }
}

.text-input {
  height: 52px;
  padding: 0 16px;
  border: {
    color: $ui-line-black;
    radius: 8px;
    style: solid;
    width: 1px;
  }
}

textarea {
  padding: 16px;
  border: {
    color: $ui-line-black;
    radius: 20px;
    style: solid;
    width: 1px;
  }
  font: {
    family: "Montserrat", sans-serif;
    weight: 400;
  }
  @include font-size($ui-font-13);

  &:focus {
    outline: none;
  }
}

@media all and (max-width: $ipad) {
  .button {
    width: 100%;
  }

  .text-input {
    width: 100%;
  }
}

/* MAIN ALL PAGES CONTAINERS */

#root {
  justify-content: center;
  width: 100%;
  height: 100%;
}

#page {
  flex-grow: 1;
  flex-shrink: 1;
  position: static;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
}

#page.page-lock {
  position: fixed;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10;
}

.content {
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  padding-left: 220px;
  background-color: $ui-bg-light;
}

@media all and (max-width: $ipad) {
  .content {
    padding-left: 0;
    margin-top: 64px;
  }
}

.section {
  position: relative;
  z-index: 2;
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  padding: 0;
}

.section.section-gray {
  background-color: $ui-bg-light;
}

.section.section-column {
  flex-direction: column;
}

.table {
  width: 100%;
  margin-top: 12px;
  border-spacing: 0;
  background-color: $ui-bg-white;

  tr {
    background-color: $ui-bg-white;

    &:nth-child(2n + 1) {
      background-color: $ui-bg-gray;
    }

    &:hover {
      background-color: $ui-bg-pink-light;
    }
  }

  th {
    padding: 4px 8px;
    border-bottom: 2px solid $ui-line-gray;
    background-color: $ui-bg-light;
    text-align: left;
  }

  td {
    padding: 2px 8px;
    border-bottom: 1px solid $ui-line-gray;
    text-align: left;

    a {
      color: $ui-text-pink;
    }
  }
}

.table-col-setting {
  position: absolute;
  right: -300px;
  top: 182px;
  z-index: 2;
  flex-direction: column;
  justify-content: flex-start;
  width: 300px;
  height: calc(100% - 182px);
  border-left: 1px solid $ui-line-gray;
  background-color: $ui-bg-white;
  transition: all 0.15s ease-out 0s;

  &.active {
    right: 0;
  }
}
