@import "../../styles/colors.scss";

.order-item {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px 12px;
  border: 1px solid $ui-line-light;
  border-radius: 4px;
  
  &-image {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    border-radius: 4px;
  }

  &-info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-direction: column;
  }

  &-title {
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    color: $ui-text-black;
    font-weight: 600;
  }

  &-text {
    color: $ui-text-gray;
  }

  &-num {
    padding: 0 24px;
  }
}
