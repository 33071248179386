@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.home-page {
  
}

.users-table-wrap {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 0;

  h2 {
    padding-left: 20px;
  }
}

.users-table-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $ui-bg-gray;
}

@media all and (max-width: $ipad) {
  
}