@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.order-page {
}

.order-wrap {
  flex-direction: column;
}

.order-title-wrap {
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 20px;

  .order-title-back {
    margin-bottom: 12px;
  }
}

.order {
  flex-direction: column;
  min-width: 600px;
  padding: 0 20px 30px;
}

.order-composition {
  flex-direction: column;
  padding: 24px 0;

  h3 {
    margin-bottom: 12px;
  }
}

.order-option {
  flex-direction: column;

  h3 {
    margin-bottom: 12px;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-item {
    margin-bottom: 8px;
    margin-right: 16px;
    padding: 8px 12px;
    border-radius: 4px;
    background-color: $ui-bg-pink-light;

    p {
      color: $ui-text-pink;
    }
  }

  &-text {
    margin-bottom: 8px;
    padding: 8px 12px;
    border: 1px solid $ui-line-light;
    border-radius: 4px;
  }
}

@media all and (max-width: $ipad) {
}
