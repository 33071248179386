@import "../../styles/media.scss";

.menu-button {
  display: none;
  padding: 16px 0;
  transition: all 0.25s ease-out 0s;

  &-icon {
    width: 32px;
    height: 15px;
  }
}

@media all and (max-width: $ipad) {
  .menu-button {
    display: flex;
  }
}