@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.check-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 12px;
  border-bottom: 1px solid $ui-line-gray;

  &-icon {
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid $ui-icon-gray;
    border-radius: 6px;
    margin-right: 15px;
    overflow: hidden;
    transition: all 0.35s ease-out 0s;

    &-check {
      font-size: 12px;
      opacity: 0;
    }
  }

  &-icon.active {
    border-color: $ui-icon-orange;
    background-color: $ui-icon-orange;
    transition: color 0.35s ease-out 0s;

    .check-item-icon-check {
      opacity: 1;
      transition: color 0.35s ease-out 0s;
    }
  }

  span {
    font: {
      family: "Montserrat", sans-serif;
      weight: 400;
    }
    @include font-size($ui-font-13);
  }
}

@media all and (max-width: $tablet) {
  .check-item {
    padding: 20px 0;
  }
}
