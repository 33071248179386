@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.settings-wrap {
  flex-direction: column;
  width: 100%;
  padding: 20px;

  .settings-item {
    flex-direction: column;
    width: 100%;
    padding: 8px 0;

    h3 {
      margin-top: 6px;
      margin-bottom: 16px;
      color: $ui-text-pink;
      font-weight: 400;
      text-transform: uppercase;
    }

    .settings-item-params {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 12px;

      .react-datepicker-wrapper {
        width: 100%;
        height: 34px;
        padding: 0 0 0 32px;
        border: 1px solid $ui-line-gray;
        border-radius: 8px;
        background-color: $ui-bg-white;

        .react-datepicker__input-container {
          height: 34px;
        }

        input {
          cursor: pointer;
          width: 100%;
          height: 32px;
        }
      }

      .react-datepicker__tab-loop {
        .react-datepicker-popper {
          display: block;

          div {
            display: block;
          }

          .react-datepicker {
            display: inline-block;
          }

          .react-datepicker__day-name {
            display: inline-block;
          }

          .react-datepicker__day {
            display: inline-block;
          }
        }
      }
    }

    .settings-item-wrap {
      align-items: flex-start;
      width: 100%;
      margin: 20px 0;
    }

    .settings-item-control {
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 50px;
      border: 1px solid $ui-line-gray;
      border-bottom-width: 0;
      background-color: $ui-bg-white;

      .settings-item-control-title {
        display: grid;
        grid-template-columns: 1fr 2.5fr 2fr 2fr;
        grid-gap: 12px;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid $ui-line-gray;
      }

      .interval-item {
        display: grid;
        grid-template-columns: 1fr 2.5fr 2fr 2fr;
        grid-gap: 12px;
        align-items: center;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid $ui-line-gray;

        .interval-item-col {
          flex-direction: column;
        }

        .interval-item-num {
          align-self: center;
          padding: 2px 8px;
          border: 1px solid $ui-line-pink;
          border-radius: 8px;
        }
      }

      .settings-item-control-title-3 {
        display: grid;
        grid-template-columns: 1.5fr 2fr 2fr;
        grid-gap: 12px;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid $ui-line-gray;
      }

      .interval-item-3 {
        display: grid;
        grid-template-columns: 1.5fr 2fr 2fr;
        grid-gap: 12px;
        align-items: center;
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid $ui-line-gray;

        .interval-item-col {
          flex-direction: column;
          
          &.input-distance-wrap {
            .text-input-wrap {
              width: 25%;
              
            }
          }
        }

        .interval-item-num {
          align-self: center;
          padding: 2px 8px;
          border: 1px solid $ui-line-pink;
          border-radius: 8px;
        }
      }


      .settings-item-save {
        position: absolute;
        right: 0;
        bottom: -50px;
      }
    }

    .settings-item-info {
      flex-grow: 1;
      flex-shrink: 1;
      flex-direction: column;
      border: 1px solid $ui-line-gray;
      background-color: $ui-bg-white;

      h4 {
        width: 100%;
        padding: 12px 20px;
        border-bottom: 1px solid $ui-line-gray;
        text-transform: uppercase;
      }

      .event-item {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid $ui-line-gray;

        &:last-child {
          border-bottom-width: 0;
        }
      }
    }
  }
}
