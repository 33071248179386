$black: #333;
$gray: #999;
$green: #58af4a;
$lilac: #eae2ed;
$orange: rgb(253, 184, 80);
$pink: rgb(226, 46, 94);
$purple: rgb(176, 7, 160);
$violet: #574161;
$white: #fff;

/* COLORS CONSTANTS */
/* background colors */
$ui-bg-gray: #f2f2f2;
$ui-bg-light: #fafafa;
$ui-bg-lilac: $lilac;
$ui-bg-orange: $orange;
$ui-bg-pink-light: rgba($pink, 0.15);
$ui-bg-violet: $violet;
$ui-bg-white: $white;

/* button colors */
$ui-btn-gray: #dfdfdf;
$ui-btn-green: $green;
$ui-btn-disabled: $gray;
$ui-btn-orange: $orange;
$ui-btn-orange-light: #ffefd5;
$ui-btn-pink: $pink;
$ui-btn-violet: $violet;
$ui-btn-white: $white;
$ui-btn-text-black: $black;
$ui-btn-text-orange: $orange;
$ui-btn-text-white: $white;

/* icon colors */
$ui-icon-black: $black;
$ui-icon-gray: $gray;
$ui-icon-orange: $orange;
$ui-icon-purple: $purple;
$ui-icon-violet: $violet;
$ui-icon-white: $white;

/* line colors */
$ui-line-black: $black;
$ui-line-gray: #ccc;
$ui-line-light: #dfdfdf;
$ui-line-orange: $orange;
$ui-line-pink: $pink;
$ui-line-purple: $purple;
$ui-line-white: $white;

/* shadow colors */
$ui-shadow: #1e2845;
$ui-shadow-black: #15110f;
$ui-shadow-orange: $orange;
$ui-shadow-pink: $pink;
$ui-shadow-purple: $purple;
$ui-shadow-violet: $violet;

/* text colors */
$ui-text-black: $black;
$ui-text-chocolate: #d45711;
$ui-text-gray: #aaa;
$ui-text-green: $green;
$ui-text-orange: $orange;
$ui-text-pink: $pink;
$ui-text-purple: $purple;
$ui-text-red: #ab432c;
$ui-text-white: $white;

/* transparent colors */
$ui-blur: rgba(221, 218, 218, 0.6);
$ui-transparent: rgba(221, 218, 218, 0.6);

/* chart colors */
$ui-chart-red: #ab432c;
$ui-chart-orange: $orange;
$ui-chart-yellow: #ffd700;
$ui-chart-green: $green;
$ui-chart-sky: #33cdc7;
$ui-chart-blue: #1049a9;
$ui-chart-violet: $purple;
$ui-chart-pink: $pink;
