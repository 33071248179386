@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 220px;
  height: 100%;
  padding: 20px 0;
  border-right: 1px solid $ui-line-gray;
  background-color: $ui-bg-white;

  &-control {
    align-items: center;
    width: 100%;
    padding: 0 16px;
  }

  .menu {
    display: none;
  }

  .nav {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;

    &-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0;

      &-item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        padding: 12px 16px;
        background-color: $ui-bg-white;

        &-link {
          text-transform: uppercase;

          &.active {
            color: $ui-text-pink;
            font-weight: 600;
          }
        }
      }
    }
  }
}

@media all and (max-width: $ipad) {
  .header {
    z-index: 9999;
    width: 100%;
    height: 64px;
    padding: 8px 0;
    border-right-width: 0;
    border-bottom: 1px solid $ui-line-gray;
    overflow: hidden;

    &.active-mobile {
      height: 100%;
    }

    .nav {
      &-list {
        &-item {
          padding: 12px 16px;
        }
      }
    } 
  }
}