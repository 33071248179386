@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.textarea-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: column;

  label {
    position: absolute;
    left: 16px;
    top: 13px;
    margin-bottom: 16px;
    padding: 2px 4px;
    background-color: transparent;
    @include font-size($ui-font-15);
    transition: all 0.25s ease-out 0s;
  }

  .textarea {
    width: 100%;
    height: 80px;
    border-color: $ui-line-gray;

    &:focus {
      border-color: $ui-line-orange;
    }

    &:focus + label {
      top: -8px;
      background-color: $ui-bg-white;
      @include font-size($ui-font-13);
      transition: all 0.25s ease-out 0s;
    }
  }
}
