@import "../../styles/colors.scss";

.switch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
}

.switch .switch-check {
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  width: 44px;
  height: 24px;
  margin-right: 12px;
  padding: 2px;
  border-radius: 16px;
  background-color: $ui-btn-gray;
  transition: all .25s ease-out 0s;
}

.switch.active .switch-check {
  background-color: $ui-btn-green;
  transition: all .25s ease-out 0s;
}

.switch .switch-check:after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $ui-bg-white;
  transition: all .25s ease-out 0s;
}

.switch.active .switch-check:after {
  left: 22px;
  transition: all .25s ease-out 0s;
}

.switch .switch-label {
  flex-grow: 1;
  flex-shrink: 1;
  font-weight: 500;
}

.switch .switch-label span {
  font-weight: 600;
}