@import "../../styles/colors.scss";

.order-info {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid $ui-line-light;
  
  &:hover {
    background-color: $ui-bg-gray;
  }

  &-label {
    flex-grow: 0;
    flex-shrink: 0;
    width: 200px;
    color: $ui-text-black;
    font-weight: 600;
  }
}
