@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.footer {
  position: relative;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 20px;
  border-top: 1px solid $ui-line-gray;
  background-color: $ui-bg-white;

  p {
    color: $ui-text-gray;
    @include font-size($ui-font-13);
    text-align: center;

    a {
      color: $ui-text-purple;
    }
  }
}

@media all and (max-width: $ipad) {
  .footer-info {
    padding-left: 20px;
    padding-right: 20px;
  }
}
