@import "../../styles/colors.scss";

.button-orange {
  width: 100%;
  margin-bottom: 12px;
  border-color: $ui-line-pink;
  background: $ui-btn-pink;
  color: $ui-btn-text-white;

  &.green {
    border-color: $ui-btn-green;
    background-color: $ui-btn-green;
  }

  &.small {
    width: auto;
    height: 34px;
    margin-bottom: 0;
  }

  &:hover {
    background-color: $ui-btn-white;
    color: $ui-btn-text-black;
    box-shadow: 0 0 4px rgba($ui-btn-pink, 0.45);
  }

  &:disabled {
    border-color: $ui-btn-green;
    background-color: $ui-btn-green;

    &:hover {
      color: $ui-btn-text-white;
      box-shadow: none;
    }
  }
}
