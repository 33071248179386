@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.button-round {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  margin: 2px 16px 2px 0;
  border: 1px solid $ui-icon-gray;
  border-radius: 18px;
  background-color: $ui-icon-gray;

  &-icon {
    color: $ui-icon-white;
    width: 16px;
    height: 16px;
  }

  &.outline {
    background-color: $ui-icon-white;

    .button-round-icon {
      color: $ui-icon-gray;
    }
  }
}