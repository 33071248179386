@import "../../styles/colors.scss";
@import "../../styles/media.scss";

.search-input-wrap {
  flex-grow: 0;
  flex-shrink: 0;
  margin-bottom: 16px;
  margin-top: 12px;
  margin-left: 20px;
  border-bottom: 1px solid $ui-line-gray;
}

.search-input {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  width: 320px;
  height: 38px;
}

.search-input-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 0;
  flex-shrink: 0;
  width: 32px;
  height: 38px;

  &-icon {
    width: 24px;
    height: 24px;
    fill: $ui-icon-black;
  }
}

@media all and (max-width: $small) {
  .search-input-wrap {
    width: 100%;
    margin-left: 0;
  }
  
  .search-input {
    width: calc(100% - 24px);
  }
}