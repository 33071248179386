@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.text-input-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  flex-direction: column;

  label {
    position: absolute;
    left: 20px;
    top: 15px;
    padding: 2px 4px;
    background-color: transparent;
    @include font-size($ui-font-15);
    transition: all 0.25s ease-out 0s;

    span {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 50%;
      background-color: $ui-bg-white;
    }

    &.filled {
      position: absolute;
      top: -10px;
      @include font-size($ui-font-13);
      transition: all 0.25s ease-out 0s;
    }
  }

  .text-input {
    width: 100%;
    border: 1px solid $ui-line-gray;
    background-color: $ui-bg-white;

    &:focus {
      border-color: $ui-line-pink;
    }

    &:focus + label {
      position: absolute;
      top: -10px;
      @include font-size($ui-font-13);
      transition: all 0.25s ease-out 0s;
    }

    &.small {
      height: 34px !important;
    }
  }
}
