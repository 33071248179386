@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.button-sort {
  display: flex;
  align-items: center;
  padding: 4px 6px;
  border-radius: 4px;
  background-color: $ui-bg-gray;
  font: {
    family: "Montserrat", sans-serif;
    weight: 700;
  }
  @include font-size($ui-font-13);

  &-icon {
    margin-left: 6px;
    font-size: 12px;
  }
}

.button-sort:hover {
  background-color: $ui-bg-white;
}