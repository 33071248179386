@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.edit-flower-page {
  .section-column {
    padding: 30px 0;

    h2 {
      width: 100%;
      padding-left: 40px;
    }
  }
}

.users-table-wrap {
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.edit-flower-table-wrap {
    flex-direction: row;
  }

  td .small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 140px;
    display: block;
  }

  .add-button-wrap {
    padding: 0 20px 16px 20px;
  }

  .image {
    width: 60px;
    height: 80px;
  }
}

.users-table-image {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $ui-bg-gray;
}

.add-content {
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 40px;
  border-radius: 2px;
  background-color: #fff;
  width: 680px;

  .modal-catalog-form {
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
  }

  
}

.modal-catalog-form-images {
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.edit-flower-page {
  .content {
    background-color: $ui-bg-white;
  }

  .edit-image-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    margin-bottom: 16px;
    border: 1px solid $ui-btn-pink;
    border-radius: 8px;
    background: $ui-btn-pink;
    color: $ui-text-white;

    font: {
      family: "Montserrat", sans-serif;
      weight: 600;
    }

    @include font-size($ui-font-15);
    transition: all 0.3s ease-out 0s;

    &:hover {
      cursor: pointer;
      background-color: $ui-btn-white;
      color: $ui-btn-text-black;
      box-shadow: 0 0 4px rgba($ui-btn-pink, 0.45);
    }
  }

  .inter-add-image {
    align-items: center;
    justify-content: center;
    width: 193px;
    height: auto;
    margin-bottom: 16px;
    margin-right: 20px;
    cursor: pointer;
    background-color: $ui-bg-gray;
  }

  .edit-image-wrap {
    flex-direction: column;

    img {
      width: 193px;
      height: auto;
      margin-bottom: 12px;
    }
  }
}