@import "./media.scss";

/* font sizes map */
$ui-font-13: (
  null: ( 13px, 1.22 ),
  $desktop: 13px,
  $ipad: 12px,
  $tablet: 12px,
  $mobile: 12px,
  $small: 12px,
);
$ui-font-14: (
  null: ( 14px, 1.22 ),
  $desktop: 14px,
  $ipad: 13px,
  $tablet: 13px,
  $mobile: 13px,
  $small: 13px,
);
$ui-font-15: (
  null: ( 15px, 1.22 ),
  $desktop: 15px,
  $ipad: 14px,
  $tablet: 14px,
  $mobile: 14px,
  $small: 14px,
);
$ui-font-16: (
  null: ( 16px, 1.22 ),
  $desktop: 16px,
  $ipad: 15px,
  $tablet: 15px,
  $mobile: 15px,
  $small: 15px,
);
$ui-font-17: (
  null: ( 17px, 1.22 ),
  $desktop: 17px,
  $ipad: 16px,
  $tablet: 16px,
  $mobile: 16px,
  $small: 16px,
);
$ui-font-18: (
  null: ( 18px, 1.39 ),
  $desktop: 18px,
  $ipad: 17px,
  $tablet: 17px,
  $mobile: 17px,
  $small: 17px,
);
$ui-font-20: (
  null: ( 20px, 1.22 ),
);
$ui-font-22: (
  null: ( 22px, 1.22 ),
);
$ui-font-23: (
  null: ( 23px, 1.22 ),
);
$ui-font-25: (
  null: ( 25px, 1.24 ),
);
$ui-font-30: (
  null: ( 30px, 1.24 ),
);
$ui-font-35: (
  null: ( 35px, 1.22 ),
);
$ui-font-45: (
  null: ( 45px, 1.22 ),
);

/* FONT SIZE SCALE MIXIN */

@mixin font-size($fs-map, $fs-breakpoints: $breakpoints) {
  @each $fs-breakpoint, $fs-font-size in $fs-map {
    @if $fs-breakpoint == null {
      @include make-font-size($fs-font-size);
    } @else {
      // If $fs-font-size is a key that exists in
      // $fs-breakpoints, use the value
      @if map-has-key($fs-breakpoints, $fs-breakpoint) {
        $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
      }
      @media screen and (max-width: $fs-breakpoint) {
        @include make-font-size($fs-font-size);
      }
    }
  }
}

// Utility function for mixin font-size
@mixin make-font-size($fs-font-size) {
  // If $fs-font-size is a list, include
  // both font-size and line-height
  @if type-of($fs-font-size) == "list" {
    font-size: nth($fs-font-size, 1);
    @if (length($fs-font-size) > 1) {
      line-height: nth($fs-font-size, 2);
    }
  } @else {
    font-size: $fs-font-size;
  }
}
