@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";
@import "../../styles/media.scss";

.login-page {
  width: 100%;
  height: 100%;
  align-items: stretch;
  background-color: $ui-bg-white;
}

.login-form {
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 600px;
  padding: 60px 90px;
  background-color: $ui-bg-light;

  &-input {
    width: 100%;
    margin-bottom: 12px;
  }
}

.login-logo {
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 260px;
  }

  h2 {
    color: $ui-text-black;
    @include font-size($ui-font-30);
    font-weight: 600;
  }

  p {
    margin: 12px 0;
    color: $ui-text-pink;
    @include font-size($ui-font-18);
    font-weight: 500;
  }
}

@media all and (max-width: $ipad) {
  
}