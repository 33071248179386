@import "../../styles/colors.scss";
@import "../../styles/fonts.scss";

.select-wrap {
  position: relative;
  width: 100%;
  margin-bottom: 12px;
  flex-direction: column;

  &.green {
    .select {
      border-color: $ui-btn-green;
      background-color: $ui-bg-white;
    }
  }

  &.pink {
    .select {
      border-color: $ui-line-pink;
      background-color: $ui-bg-white;
    }
  }

  label {
    margin-bottom: 4px;
    @include font-size($ui-font-13);
  }

  .select-container {
    position: relative;
  }

  .select {
    position: relative;
    width: 100%;
    height: 34px;
    padding: 0 36px 0 16px;
    border: 1px solid $ui-line-gray;
    border-radius: 8px;
    color: $ui-text-black;
    font: {
      family: "Montserrat", sans-serif;
      weight: 400;
    }
    @include font-size($ui-font-13);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;

    &.icon-first {
      padding: 0 36px;
      text-align: left;
    }

    option {
      color: $ui-text-black;
      font: {
        family: "Montserrat", sans-serif;
        weight: 400;
      }
      @include font-size($ui-font-13);
    }

    &-icon {
      position: absolute;
      top: calc(50% - 3px);
      right: 16px;
      width: 12px;
      height: 6px;
    }
  }

  .select-calendar {
    position: absolute;
    top: calc(50% - 6px);
    left: 12px;
    width: 12px;
    height: 12px;
    fill: $ui-icon-gray;
  }
}
